body {
  background-color: white;
}

body, input {
  font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif !important;
}

.mantine-TextInput-error {
  font-size: 12px;
}

.mantine-Modal-modal {
  margin-top: 0;
}

.mantine-Modal-inner {
  padding-left: 0;
  padding-right: 0;
}

.mantine-Select-dropdown,
.mantine-Notification-root,
.mantine-Autocomplete-dropdown {
  // box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.mantine-Select-item[data-selected] {
  // background-color: #ced6ec !important;
  background-color: #c9dfe5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mantine-TextInput-input,
.mantine-PasswordInput-innerInput {
  height: 2rem !important;
}

.mantine-Modal-content {
  overflow-y: visible;
}

.mantine-Modal-header {
  border-radius: 0.25rem;
}

.mantine-Modal-body .mantine-Select-input {
  height: 2.25rem !important;
  min-height: 2.25rem !important;
}

.rdt_Pagination {
  min-height: 40px !important;
  height: 40px;
}

.rdt_TableRow:hover {
  outline-color: transparent !important;
}
.rdt_TableRow:not(:last-of-type):hover {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
